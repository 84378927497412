import { useContext, useRoute, useStore } from '@nuxtjs/composition-api';
import { State } from '~/store';

interface UseTwoRowHeader {
  openNavEvent: (navigationName: string) => void;
  linkClickedEvent: (navigationName: string, linkName: string | null) => void;
  navigationUserClickedEvent: (itemType: string, itemName: string, itemPath?: string) => void;
  eventSkipLinkClicked: () => void;
}

export const useTwoRowHeader = (): UseTwoRowHeader => {
  const { $amplitude, $config, i18n, $mzAnalyticsApi } = useContext();
  const route = useRoute();
  const store = useStore<State>();
  const openNavEvent = (navigationName: string): void => {
    const pageName = process.client && document.title ? document.title.split('|')[0] : undefined;
    $amplitude.track('Navigation | Dropdown Opened', {
      Page_Url: `${$config.baseURL}${route.value.path}`,
      Page_Country: i18n.locale,
      Page_Name: pageName,
      Navigation_Name: navigationName,
    });
    if (navigationName === 'Content') {
      $mzAnalyticsApi({
        event: 'click',
        name: 'content',
        productArea: 'content',
        entityId: '',
        entityType: '',
      });
    }
  };
  const linkClickedEvent = (navigationName: string, linkName: string | null): void => {
    const pageName = process.client && document.title ? document.title.split('|')[0] : undefined;
    $amplitude.track('Navigation | Dropdown Item Clicked', {
      Page_Url: `${$config.baseURL}${route.value.path}`,
      Page_Country: i18n.locale,
      Page_Name: pageName,
      Navigation_Name: navigationName,
      Link_Name: linkName,
    });
    const { productAreas, analyticsName } = ((): {
      productAreas: string;
      analyticsName: string;
    } => {
      if (!linkName) {
        return { productAreas: 'resources', analyticsName: 'resources.unknown' };
      }
      const lowerLinkName = linkName.toLowerCase();
      let productAreas = 'resources';
      let analyticsName = 'resources.unknown';

      if (navigationName === 'Resources') {
        productAreas = 'resources';
        analyticsName = `top-nav.resources.${lowerLinkName
          .replace(/\s+/g, '_')
          .replace(/&/g, 'and')}`;
      } else if (navigationName === 'Learning Areas') {
        productAreas = 'learning area/subject';
        if (i18n.locale === 'gb') {
          analyticsName = `top-nav.learning_areas_gb.${lowerLinkName
            .toLowerCase()
            .replace(/\s+/g, '_')
            .replace(/\//g, '_')
            .replace(/\(.*?\)/g, match => match.replace(/\s+/g, '_'))}`;
        } else if (i18n.locale === 'au') {
          analyticsName = `top-nav.learning_areas.${lowerLinkName
            .toLowerCase()
            .replace(/\s+/g, '_')
            .replace(/\//g, '_')
            .replace(/\(.*?\)/g, match => match.replace(/\s+/g, '_'))}`;
        }
      } else if (navigationName === 'Year Levels') {
        productAreas = 'year_levels';
        analyticsName = `top-nav.year_levels.${lowerLinkName
          .toLowerCase()
          .replace(/\s+/g, '_')
          .replace(/\//g, '_')}`;
      } else if (navigationName === 'Topics') {
        productAreas = 'topics';
        analyticsName = 'top-nav.topics.';
        if (lowerLinkName.includes('|')) {
          analyticsName = analyticsName
            .concat(lowerLinkName.split('|')[0].trim().replace(/\s+/g, '_'))
            .concat('.')
            .concat(lowerLinkName.split('|')[1].trim().replace(/\s+/g, '_'));
        } else {
          analyticsName = analyticsName.concat(lowerLinkName.trim().replace(/\s+/g, '_'));
        }
      } else if (navigationName === 'Content') {
        productAreas = 'content';
        analyticsName = 'content.' + lowerLinkName;
      } else if (navigationName === 'Subjects') {
        productAreas = 'learning area/subject';
        analyticsName = `top-nav.subjects.${lowerLinkName
          .replace(/\s+/g, '_')
          .replace(/\(/g, '')
          .replace(/\)/g, '')}`;
      } else if (navigationName === 'Grades') {
        productAreas = 'grades';

        if (lowerLinkName === 'pre-k' || lowerLinkName === 'all grades') {
          analyticsName = `top-nav.grades.${lowerLinkName.replace(/\s+/g, '_')}`;
        } else {
          analyticsName = `top-nav.grades.${lowerLinkName
            .replace(/\s+/g, '_')
            .replace('th_', 'th_')
            .replace('rd_', 'rd_')
            .replace('nd_', 'nd_')
            .replace('st_', 'st_')}`;
        }
      } else if (navigationName === 'Stages') {
        productAreas = 'stages';
        analyticsName = 'top-nav.stages.' + lowerLinkName.trim().replace(/\s+/g, '_');
      }
      return { productAreas, analyticsName };
    })();
    $mzAnalyticsApi({
      event: 'click',
      name: analyticsName,
      productArea: productAreas,
      entityId: '',
      entityType: '',
    });
    store.dispatch('toggleMobileNav', false);
  };
  // new navigation event for new-navigation experiment
  const navigationUserClickedEvent = (
    itemType: string,
    itemName: string,
    itemPath?: string
  ): void => {
    $amplitude.track('Navigation | User Clicked', {
      Item_Type: itemType,
      Item_Name: itemName,
      Item_Url: itemPath?.length ? `${$config.baseURL}${itemPath}` : undefined,
    });
  };

  const eventSkipLinkClicked = (): void => {
    $amplitude.track('Navigation | Skip navigation link clicked', {
      Page_Url: `${$config.baseURL}${route.value.path}`,
    });
  };

  return {
    openNavEvent,
    linkClickedEvent,
    navigationUserClickedEvent,
    eventSkipLinkClicked,
  };
};
