<template>
  <div>
    <footer id="footer" class="tw-relative tw-z-[9] tw-bg-chalkboard" data-cy="main-footer">
      <div class="tw-px-4 tw-text-white">
        <div class="row">
          <div class="columns small-12 medium-6 large-3">
            <h3>About Us</h3>
            <ul class="footer__link-list">
              <li>
                <nuxt-link
                  v-if="loggedInUser?.isAuthenticated"
                  :to="localePath('/about/')"
                  title="Who we are"
                  @click.native="aboutUsClick('about_us')"
                >
                  About Us
                </nuxt-link>
                <a
                  v-else
                  :href="localePath('/about/')"
                  title="Who we are"
                  @click="aboutUsClick('about_us')"
                >
                  About Us
                </a>
              </li>
              <li>
                <nuxt-link
                  :to="localePath('/one-million-trees-project/')"
                  title="Help us plant one million trees"
                  @click.native="aboutUsClick('one_million_trees')"
                >
                  One Million Trees
                  <span class="hidden-small">&nbsp;Project</span>
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="localePath('/testimonials-reviews/')"
                  title="What our customers say about Teach Starter"
                  @click.native="aboutUsClick('testimonials_reviews')"
                >
                  Testimonials &amp; Reviews
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="localePath('/careers/')"
                  title="Work with us"
                  @click.native="aboutUsClick('careers')"
                >
                  Careers
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div class="columns small-12 medium-6 large-3">
            <h3>More</h3>
            <ul class="footer__link-list">
              <li>
                <nuxt-link
                  :to="localePath('/plans/')"
                  title="Choose a plan and become a member"
                  @click.native="moreClick('plans_pricing')"
                >
                  Plans &amp; Pricing
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="localePath('/curriculums/')"
                  title="Browse resources by curriculum"
                  @click.native="moreClick('curriculums')"
                >
                  Curriculums
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  to="/au/"
                  title="Teach Starter Australia"
                  @click.native="moreClick('teach_starter_australia')"
                >
                  Teach Starter Australia
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  to="/us/"
                  title="Teach Starter United States"
                  @click.native="moreClick('teach_starter_united_states')"
                >
                  Teach Starter United States
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  to="/gb/"
                  title="Teach Starter United Kingdom"
                  @click.native="moreClick('teach_starter_united_kingdom')"
                >
                  Teach Starter United Kingdom
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div class="columns small-12 medium-6 large-3">
            <h3>Ways To Connect</h3>
            <ul class="footer__link-list">
              <li>
                <a
                  href="https://support.teachstarter.com/"
                  title="Advice and answers from the Teach Starter Team"
                  @click="waysToConnectClick('help_desk')"
                >
                  Help Desk
                </a>
              </li>
              <li>
                <a
                  href="https://support.teachstarter.com/support/solutions/articles/51000349243-teach-starter-support"
                  title="Reach us by live chat or email"
                  @click="waysToConnectClick('contact_us')"
                >
                  Contact Us
                </a>
              </li>
              <li>
                <nuxt-link
                  :to="localePath('/suggest-a-resource/')"
                  title="Ask Teach Starter to create a resource"
                  @click.native="waysToConnectClick('suggest_a_resource')"
                >
                  Suggest A Resource
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div class="columns small-12 medium-6 large-3">
            <h3>Keep In Touch</h3>
            <ul class="tw-flex">
              <li v-for="socialLink in socialLinks[$i18n.locale]" :key="socialLink.ariaLabel">
                <a
                  :href="socialLink.url"
                  :title="socialLink.title"
                  @click="socilaLinkClick(socialLink.alt.toLowerCase())"
                >
                  <img
                    class="icon icon-small"
                    role="img"
                    :src="'/images/social/' + socialLink.icon"
                    :alt="socialLink.alt"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row sub-footer">
        <div class="tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-center tw-text-white">
          <button
            class="button button-link !tw-inline-flex tw-items-center tw-border-none !tw-shadow-none"
            title="Change location"
            @click="toggleLocationSelectorModal(true)"
          >
            <nuxt-img
              :src="
                location.code ? '/images/flags/' + location.code + '.svg' : '/images/flags/au.svg'
              "
              alt=""
              class="tw-w-4"
              height="30"
              width="40"
            />
            &nbsp;
            {{ location.name || $i18n.locales.find(l => l.code === $i18n.locale).name }}
          </button>
          ·&nbsp;&nbsp;
          <nuxt-link
            :to="localePath('/about/terms-and-conditions/')"
            title="Read the Terms & Conditions of using Teach Starter"
            @click.native="footerClick('terms_and_conditions')"
          >
            Terms &amp; Conditions
          </nuxt-link>
          &nbsp;&nbsp;·&nbsp;&nbsp;
          <a
            href="https://info.teachstarter.com/promotional-termsandconditions/"
            title="Read the Promotional Terms & Conditions of Teach Starter"
            rel="noindex, nofollow"
            @click="footerClick('promotion_terms_and_conditions')"
          >
            Promotion T&Cs
          </a>
          &nbsp;&nbsp;·&nbsp;&nbsp;
          <nuxt-link
            :to="localePath('/about/privacy-policy')"
            title="Read Teach Starter Privacy Policy"
            @click.native="footerClick('privacy_policy')"
          >
            Privacy Policy
          </nuxt-link>
          <!-- Dont show sitemap link for UK users -->
          <template
            v-if="location.code.toLowerCase() === 'au' || location.code.toLowerCase() === 'us'"
          >
            &nbsp;&nbsp;·&nbsp;&nbsp;
            <nuxt-link
              :to="localePath('/sitemap/')"
              title="Sitemap"
              @click.native="footerClick(location.code.toLowerCase() + '_sitemap')"
            >
              {{ location.code ? location.code.toUpperCase() : '' }} Sitemap
            </nuxt-link>
          </template>
          &nbsp;&nbsp;·&nbsp;&nbsp;
          <nuxt-link
            v-if="$route.path !== '/login/' && !isAuthenticated"
            :to="localePath('/login/')"
            nofollow
            title="Log in to your account"
            @click.native="footerClick('log_in')"
          >
            Log In
          </nuxt-link>
          <template v-else-if="isAuthenticated">
            <a
              v-if="$isFlagOn('settings')"
              :href="localePath('/member-settings/profile/')"
              title="View your member settings"
              @click="footerClick('my_account')"
            >
              My Account
            </a>
            <a
              v-else
              :href="$config.wpURL + '/member/profile/?amp_device_id=' + deviceId"
              title="View your member settings"
              @click="footerClick('my_account')"
            >
              My Account
            </a>
          </template>
          <nuxt-link
            :to="localePath('/')"
            title="Return home"
            class="footer-copyright-link tw-ml-4 lg:tw-ml-auto"
          >
            &copy; {{ thisYear }} Teach Starter Pty Ltd.
          </nuxt-link>
        </div>
      </div>
    </footer>

    <modals />
  </div>
</template>

<script>
/* MainFooter
 *
 * @parent:
 * @requests:
 * @events:
 * @props:
 * @components: Modals
 * @methods:
 */

import { mapGetters } from 'vuex';
import Modals from '~/components/modals/Modals.vue';
import socialLinks from '~/data/social_links.json';

export default {
  name: 'MainFooter',
  components: {
    Modals,
  },
  data() {
    return {
      thisYear: new Date().getFullYear(),
      showLocationSelector: false,
      selectedCountry: 'Australia',
      socialLinks,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'location', 'deviceId', 'loggedInUser']),
  },
  methods: {
    toggleLocationSelectorModal(show) {
      this.$store.dispatch('toggleLocationSelectorModal', show);
    },
    aboutUsClick(menu) {
      this.$mzAnalyticsApi({
        event: 'click',
        name: 'footers.about_us.' + menu,
        productArea: 'footers',
      });
    },
    moreClick(menu) {
      this.$mzAnalyticsApi({
        event: 'click',
        name: 'footers.more.' + menu,
        productArea: 'footers',
      });
    },
    waysToConnectClick(menu) {
      this.$mzAnalyticsApi({
        event: 'click',
        name: 'footers.ways_to_connect.' + menu,
        productArea: 'footers',
      });
    },
    socilaLinkClick(menu) {
      this.$mzAnalyticsApi({
        event: 'click',
        name: 'footers.social_media_links.' + menu,
        productArea: 'footers',
      });
    },
    footerClick(menu) {
      this.$mzAnalyticsApi({
        event: 'click',
        name: 'footers.' + menu,
        productArea: 'footers',
      });
    },
  },
};
</script>
<style scoped lang="scss">
a {
  color: white;
  opacity: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

a {
  opacity: 0.8;
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 1;
    text-decoration: none;
  }
}
.footer__link-list,
.sub-footer {
  a,
  button {
    &:hover,
    &:focus {
      text-decoration: none;
      color: white;
      background-color: $chalkboard-green-700;
    }

    &:active {
      text-decoration: none;
      color: white;
      background-color: $chalkboard-green-600;
    }
  }
  li {
    line-height: 1;
    padding: 0;

    > * {
      align-items: center;
      display: flex;
      padding: $base-padding;
      margin-left: -$base-padding;
      width: 100%;

      > svg,
      .label {
        flex: 0 0 auto;
      }
    }
  }
}

.sub-footer {
  align-items: center;
  font-size: $font-size-300;
  padding: $base-padding * 2;
  background-color: $chalkboard-green-500;

  a {
    padding-left: $base-padding-half;
    padding-right: $base-padding-half;
  }
  .button.button-link {
    color: white;
    font-size: inherit;
    margin: 0;
    padding: $base-padding-half;

    &:hover,
    &:focus {
      background-color: $chalkboard-green-700;
      color: white;
      text-decoration: none;
    }
  }

  .footer-copyright-link {
    float: right;
  }
}
@media #{$small-only} {
  .sub-footer {
    padding-top: $base-padding;

    .column .medium-8 {
      padding-left: 0;
    }
    .footer-copyright-link-column {
      margin-top: 1rem;
    }

    .footer-copyright-link {
      float: none !important;
    }
  }
}
</style>
