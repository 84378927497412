import { render, staticRenderFns } from "./IconLink.vue?vue&type=template&id=4522eb74"
import script from "./IconLink.vue?vue&type=script&setup=true&lang=ts"
export * from "./IconLink.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports