export const flags: Record<string, boolean> = {
  'cache-search': false,
  'email-verification': false,
  recaptcha: true,
  'high-value-categories': true,
  'password-reset': true,
  'new-subscribe': true,
  'freshdesk-widget': true,
  'signup-redirect': true,
  'change-location-btn-header': true,
  'hide-resource-contributors': true,
  'show-more-comments-btn': true,
  'new-filter-attribute': true,
  'enable-referral-program-for-free-members': true,
  'show-credits-on-checkout': true,
  'download-limits': true,
  'none-v1-free-signup': true,
  settings: true,
  'filtered-landing-pages': true,
  'australian-curriculum-nine': true,
  'no-results-page': true,
  'event-landing-pages': true,
  'saasquatch-integration': true,
  'free-download-premium': true,
  'update-card-tags': true,
  'new-upgrade-button': true,
  paypal: true,
  'billing-page-paypal': true,
  'school-plan-discount': false,
  'new-navigation': false,
};
export default flags;
