const middleware = {}

middleware['amplitude-experiment'] = require('../middleware/amplitude-experiment.ts')
middleware['amplitude-experiment'] = middleware['amplitude-experiment'].default || middleware['amplitude-experiment']

middleware['previousUrl'] = require('../middleware/previousUrl.ts')
middleware['previousUrl'] = middleware['previousUrl'].default || middleware['previousUrl']

middleware['redirects'] = require('../middleware/redirects.ts')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

export default middleware
